.be-progress-bar{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  .progress-bar__wrapper{
    height: 0.5rem;
    border-radius: 0.5rem;
    width: 100%;
    position: relative;
    .progress-bar__progress{
      height: 0.5rem;
      border-radius: 0.5rem;
    }
    .icon-container{
      position: relative;
      height: inherit;
    }
  }
  .progress-bar__percentage{
    font-size: 0.8rem;
    color: black;
  }
}