@import '../../../../../Config/Theme/primary_theame.scss';

.dashboard-custom-graph{
  position: relative;
  .custom-graph-header{
    position: absolute;
    display: none;
    top: -1.5rem;
    left: -0rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-bottom: 3rem;
    padding-right: 1rem;
    .custom-graph-drag-icon,.custom-graph-delete-icon{
      height: 1rem;
      cursor: pointer;
    }
  }
  &:hover .custom-graph-header{
    display: flex;
  }
  .custom-graph-type{
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
  }
}