@import '../../../../Config/Theme/primary_theame.scss';

.notification-container{
  // margin-left: auto;
  z-index: 10;
  position: relative;
  .notification-icon{
    position: relative;
    cursor: pointer;
    height: 25px;
    .notification-count{
      position: absolute;
      height: 14px;
      width: 14px;
      background-color: $amber;
      color: white;
      font-size: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      top: 0px;
      right: -2px;
    }
  }
  .notifications-box-outer{
    position: absolute;
    top: 2rem;
    right: 0;
    width: 344px;//294px;
    // padding-top: 2rem;
    background-color: transparent;
  }
  .notifications{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    max-height: 500px;
    overflow-y: auto;
    .mark-all-read{
      text-align: right;
      cursor: pointer;
      color: $primaryGray;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 0.5rem ;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: $primaryGreyLight;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: $primaryGray;
      border-radius: 5px;
    }

    .p{
      margin: 0;
      padding: 0;
    }
    &>*:not(:first-child){
      border-top: 1px solid $primaryGreyLight;
    }
      .notification{
        position: relative;
        padding: 1rem;
        display: flex;
        .message{
          font-size: 0.9rem;
          width: 95%;
        }
        .date-time{
          font-size: 0.7rem;
          color: $primaryGray;
          margin-top: 0.5rem;
        }
      }
      .notification-status{
        position: absolute;
        top: 1rem;
        right: 1rem;
        .notification-status-mark{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
  }
}