@import '../../../../../../Config/Theme/primary_theame.scss';

.supplier-step-2{
    padding: 1rem;
    .top-section-title{ 
      display: flex;
      justify-content: space-between;
    }
    h3{
      margin-bottom: 0.5rem;
    }
    .all-questions{
      display: grid;
      grid-template-columns: calc(200px + 2rem) 1fr;
      gap: 1rem;
      .side-items{
        height: 50rem;
        overflow-y: auto;
        overflow-x: hidden;
        width: calc(200px + 2rem);
        .question-section{
          width: 200px;
          padding: 1rem;
          display: flex;
          flex-direction: column;
          gap:0.5rem;
        }
      }
        //  modify scroll bar
        .side-items::-webkit-scrollbar {
          width: 5px;
        }
        .side-items::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: $primaryGray;
        }
    }
}