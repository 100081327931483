@import '../../../../../Config/Theme/primary_theame.scss';

.dashboard-scope-emissions{
  .emissions-details{
    display: grid;
    grid-template-columns: 2fr 4fr 4fr;
    align-items: center;
    justify-content: center;
    border: $primaryGreyLight solid 1px;
    .detail-box{
      padding: 1rem;
      height: calc(100% - 2rem);
      width: 100%;
      display: flex;
      justify-content: center;
      border-right: $primaryGreyLight solid 1px;
      .detail-box-container{
        .total-value{
          font-size: 1.2rem;
        }
        .detail-box-heading{
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .detail-box-content{
          font-size: 0.8rem;
          color: $primaryGray;
        }
      }
      .detail-content-container{
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 2fr 1fr;
      }
    }
  }
}