@import '../../../../Config/Theme/primary_theame.scss';

.report-progress-card{
	display: flex;
	width: 100%;
	gap: 1rem;
	align-items: center;
	margin-bottom: 2rem;
	.icon-container{
		width: 40px;
		height: 40px;
		border: 1px solid $primaryGreyLight;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}