@import '../../../../../Config/Theme/primary_theame.scss';

.add-template {
  height: 100%;
  width: 100%;

  .add-template-step1 {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    .search-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .search-box-input {
      width: 313px;
    }

    .framework-box {
      padding: 1rem;
      border: 1px solid $primaryGreen;
      border-radius: 10px;
      display: flex;

      .framework-icon-box {
        display: flex;
        width: 292px;
        height: 220px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: $primaryGreenLight;

        .framework-icon {
          height: 115px;
          max-width: 95%;
        }
      }

      .framework-info-box {
        padding-left: 2rem;
        margin-left: 2rem;
        border-left: 1px solid $primaryGreyLight;
        width: 100%;
        display: flex;
        align-items: center;

        .framework-title {
          font-size: 1.25rem;
          font-weight: 600;
          color: $primaryBlack;
        }

        .framework-description {
          padding-left: 1rem;

          p {
            font-size: 1rem;
            color: $primaryBlack;
            font-weight: 400;
          }
        }
      }
    }

    .framework-slider-box {
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      justify-content: center;

      .framework-slider {
        display: flex;
        align-items: center;
        gap: 1rem;

        .arrow-left {
          width: 1.2rem;
          transform: rotate(180deg);
        }

        .arrow-right {
          width: 1.2rem;
        }

        .arrow-right,
        .arrow-left {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .framework-slider-item {
          cursor: pointer;
          .framework-slider-icon {
            opacity: 0.5;
            width: 3rem;
          }
        }
       .framework-slider-item:nth-child(5) {
          .framework-slider-icon {
            opacity: 1;
            width: 5rem;
          }
        }
      }
    }
  }
  
  .add-template-step2{
    padding-top: 0.5rem;

    .gresb-description {
      background-color: $primaryGreyLight;
      padding: 1rem;
      border-radius: 5px;
    }

    .gresb-section-selection {
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .gresb-section {
        border: 1px solid $primaryGray;
        border-radius: 5px;
        padding: 1rem;
        width: 47%;
        margin: 1rem 0;
      }
    }
  }
}