@import '../../Config/Theme/primary_theame.scss';

.ant-card .ant-card-body{
	padding:0;
	
}
.activity{
	display: flex;
	height: 600px;
	.actions{
		height: inherit;
		border-right: 1px solid $primaryGreyLight;
		.actions-header{
			display: flex;
			justify-content: space-between;
			z-index: 5;
			margin: 0.5rem;
			padding: 0.5rem;
			min-width: 200px;
			.filter{
				position: relative;
				.filter-count{
					position: absolute;
					height: 14px;
					width: 14px;
					background-color: $amber;
					color: white;
					font-size: 0.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					top: 0px;
					right: -2px;
				  }
			}
		}
		.actions-timeline{
			overflow-y: auto;
			height: calc(100% - 8rem);
			margin: 0.5rem;
			padding: 1rem;
			display: flex;
			justify-content: center;
			&::-webkit-scrollbar {
				width: 5px;
			}
			&::-webkit-scrollbar-thumb {
				background: rgb(102, 102, 102);
			}
			&::-webkit-scrollbar-track {
				background: rgb(243, 243, 243);
				width: 2px;
			}
		}
	}
	

	.chats-container{
		width: 100%;
		position:relative;
		.chats{
			width: 100%;
			overflow-y: auto;
			height: calc(100% - 4rem);
			background-color:$primaryBlueLight;
			&::-webkit-scrollbar {
				width: 5px;
			}
			&::-webkit-scrollbar-thumb {
				background: rgb(102, 102, 102);
			}
			&::-webkit-scrollbar-track {
				background: rgb(243, 243, 243);
				width: 2px;
			}
			.chat{
				display: flex;
				justify-content: flex-start;
				padding: 0.5rem;
				&.send{
					justify-content: end;
				}
				.message{
					display: flex;
					width: fit-content;
					gap: 0.5rem;
					&.send{
						flex-direction: row-reverse;
					}
					.user-details{
						display: flex;
						gap: 1rem;
						margin-bottom: 0.4rem;
						&.send{
							flex-direction: row-reverse;
						}
					}
					.message-card{
						border-top-left-radius: 0;
						padding: 0.5rem;
						&.send{
							border-top-right-radius: 0;
							border-top-left-radius: 8px;
						}
						&.reject{
							background-color: #FFF8F8;
						}
					}
				}
			}
		}
	}
	
}
//bechat
