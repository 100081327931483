.dashboard-overview{
  .dashboard-overview-header{
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }
  .dashboard-emissions-by-source{
    display: grid;
    grid-template-columns:3fr 2fr;
    gap: 1rem;
    .dashboard-emissions-by-source-details{
      padding:1rem;
      .year-selector{
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        align-items: center ;
      }
    }
  }
}