@import "../../../../../../../../../../Config/Theme/primary_theame.scss";

.type-0 {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .autofill{
        .be-text-area{
            background-color: rgb(230, 250, 230);;
        }
    }
}