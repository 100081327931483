@import "../../Config/Theme/primary_theame.scss";

.menu-bar {
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
  .version{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    font-size: 0.8rem;
    color: $primaryGray;
  }
  .collapse-btn {
    position: absolute;
    top: 2.5rem;
    right: -0.5rem;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    z-index: 10;
  }

  .logo{
    cursor: pointer;
    padding-top: 1.65rem;
  }
  .menu-list {
    margin: 2.2rem auto;
    margin-bottom: 0;
    padding: 0;

    .list-itm {
      padding: 0.5rem 1.5rem;
      margin: 0 auto;
      cursor: pointer;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        color: $primaryGreenHover !important;
      }

      span{
        margin: 0;
        padding: 0;
      }
    }
    .list-itm-red {
      padding: 0.5rem 1.5rem;
      padding-bottom: 0;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}


.menu-bar-btm-user-profile{
  .user-dropdown-menu{
    color: $primaryGray;
    .user-dropdown-item{
      padding: 0.5rem;
      color: $primaryGray;
      display: flex;
      gap: 0.5rem;
      &:hover{
        background-color: $primaryGreyLight;
        cursor: pointer;
      }
      .icon{
        height: 1rem;
        width: 1rem;
      }
    }
  }
  .user-dropdown {
    margin: 0.5rem 0.5rem;
    padding: 0.3rem 0.5rem;
    border-radius: 7px;
    height: max-content;
    &:hover {
      cursor: pointer;
    }
  }
  .profile-dropdown{
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 200px;
    // height: 200px;
    padding: 0.5rem;
    background-color: white;
    bottom: 0;
    border-radius: 10px;
  }
  .divider{
    border-top: 0.1px solid $primaryGreyLight;
    margin: 0.3rem 0;
  }
  .dropdown-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  
  .avatar {
    height: 20px;
    border-radius: 50%;
    .no-profile{
      height: 2rem;
      width: 2rem;
      background-color: $amber;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.no-image {
      margin-bottom: 0;
    }
    &.with-image {
      margin-bottom: 0.6rem;
    }
  }
  
  .avatar-image {
    height: 30px;
    border-radius: 50%;
    width: 30px;
  }
  
  .user-details {
    h3 {
      font-size: 0.9rem;
      font-weight: 300;
    }
    p {
      font-size: 0.7rem;
      color: $primaryGreyLight;
    }
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
  }  
}