.editable-cell {
  padding: 0;
  min-width: 0;
  &.autofill{
    background-color: rgb(230, 250, 230);
  }
  .ant-input:focus {
    border-color: transparent !important;
    outline: 0 !important;
    -webkit-box-shadow: no+ne !important;
    box-shadow: none !important;
  }

  .ant-input {
    border-color: transparent !important;
    outline: 0 !important;
    background-color: transparent !important;
    -webkit-box-shadow: no+ne !important;
    box-shadow: none !important;
    padding: 0!important;
    min-width: 0!important;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    padding: 0!important;
  }
  .ant-form-item-control-input{
    min-height: 0px!important;
  }
}