$primaryGreen: #2e9844;
$primaryGreenDark: #21453c;
$primaryGreenHover:#96cba1;
$primaryGreenDisabled: rgb(46,152,68,0.5);
$primaryGreenLight: #e2f0eb;
$primaryGreenLightest: rgba(46, 152, 68, 0.06);
$secondaryGreen: rgb(0,184,118);
$secondaryGreenDark: #235e4a;
$secondaryGreenLight: #4FA556;
$primaryGray:rgba(102, 102, 102, 1);
$primaryGreyDark:rgb(159,159,159);
$primaryGreyLight:rgb(243, 243, 243, 1);
$primaryGreyLightest:rgb(250, 250, 250, 1);
$primaryGreyBorder:#EEEEEE;
$primaryBlack:#232323;
$primaryBlackLight: #101010;
$secondaryBlack: #181818;
$primaryRedError: #f04f6d;
$primaryAmberLight: #fcebda;
$secondaryRedError: #ED6262;
$primaryBlueInfo:#3382de;
$primaryBlueLight:#f5f9fa;
$secondaryGray: #9F9F9F;
$amber: #F09948;
$primaryYellowLight: #edecb5;
$primaryGoldenBrown: #8A5000;
$primaryVividBlue: #0163BC;
$primaryRedLight:rgba(240, 79, 109, 0.2);
$primaryGoldenBrownLight: rgba(138, 80, 0, 0.1);
$primaryVividBlueLight: rgba(1, 99, 188, 0.1);
$primaryRedErrorBorder: #F04F6D;
$primaryRedErrorLight: #FFF1F1;
