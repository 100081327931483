@import '../../../../../../../../Config/Theme/primary_theame.scss';

.children-right {
    padding: 1rem;
    .data-table-box{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .ant-tabs-content-holder{
            margin-top: 1rem;
        }
        .heading{
            cursor: pointer;
            gap: 1rem;
        }
        .topic-heading{
            display: flex;
            justify-content: space-between;
            gap: 1rem;
            align-items: center;
            width: 100%;
            >p{
                font-size: 1rem;
                font-weight: 600;
                color: $primaryBlack;
            }
        }
        .right-heading{
            display: flex;
            gap: 1rem;
            align-items: center;
            margin-left: auto;
            max-width: 70rem;
        }

        .data-table{
            padding: 1rem;
        }
        .table-title{
            max-width: 20rem;
        }
        .activity-toggle-btn{
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            margin: 1rem;
            .icon{
                height: 1rem;
                transition: all 0.2s ease;
            }
        }
    }
    
}

.ant-modal-content{
    .ant-tabs-content-holder{
        margin-top: 1rem;
    }
}