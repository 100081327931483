@import '../../../../Config/Theme/primary_theame.scss';

.insights-container{
	display: flex;
	gap: 1rem;
	.report-completion-status{
		padding: 1rem;
		height: 50vh;
		padding-right: 2rem;
		overflow-y: auto;
	}
	.alert-message-container{
		height: 50vh;
		overflow-y: auto;
		padding: 1rem;
		.alert-message{
			display: flex;
			gap: 3rem;
			align-items: center;
			color: $primaryGray;
			padding: 0.5rem;
			margin-bottom: 1.5rem;
		}
	}
}
.comparison-wrapper{
	display: grid;
	grid-template-columns: 3fr 2fr;
	gap: 1rem;
	.details {
	  padding:1rem;
	  .year-selector {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		align-items: center ;
	  }
	}
  }

