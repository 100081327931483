@import '../../../../../Config/Theme/primary_theame.scss';

.drilldown-bu-bg-emissions{
  padding: 1rem;
  padding-top:4rem;
  margin-top: 4rem;
  border-top: 2px solid $primaryGreyLight;
  .bu-bg-emission-graphs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    .title{
      font-size: 1rem;
      color: $primaryGray;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}