@import '../../../../Config/Theme/primary_theame.scss';

.horizontal-bar-chart-1{
    width: 100%;
    background-color: white;
    .bar-container{
      width: calc(100% - 2rem);
      border-radius: 0.5rem;
      padding: 1rem;
      margin-bottom: 0.5rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      cursor: pointer;
      &.selected{
        background-color: $primaryGreyLight
      }
      .bar-name{
        font-size: 0.8rem;
        // only one  line text and show ... if text is more than one line
        color: $primaryGray;
        width: 7rem;
        // display: inline-block; /* or block, depending on your layout */
        // white-space: nowrap; /* Prevent text from wrapping to the next line */
        // overflow: hidden; /* Hide the overflowed content */
        // text-overflow: ellipsis; /* Add "..." to indicate overflow */
        // cursor: pointer; /* Optional: indicate that it's interactive */
      }
      .bar-wrapper{
        width: 100%;
        display: flex;
        align-items: center ;
        gap: 1rem;
        .bar{
          height: 2rem;
          border-radius: 0 0.5rem 0.5rem 0;
          // position: relative;
          .bar-tooltip{
            position: absolute;
            top: -3rem;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            border-radius: 0.5rem;
            padding: 1rem;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
            border: 1px solid $primaryGreyLight;
            font-size: 0.8rem;
            color: $primaryGray;
            white-space: nowrap;
            display: none;
          }
        }
        .bar-value{
          color: $primaryGray;
          font-size: 0.8rem;
          white-space: nowrap;
        }
        &:hover{
          scale: 1;
          .bar-tooltip{
            display: block;
          }
        }
      }
    }
    .pagination-container{
      display: flex;
      justify-content: center;    
    }
}