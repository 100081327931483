@import '../.../../../../../Config/Theme/primary_theame.scss';


.increase-symbol, .decrease-symbol{
  border: 1px solid $primaryGreyLight;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  &>div{
    height:0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.increase-symbol>div{
  transform: rotate(180deg);
}

.comparison-card{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .comparison-card-item{
    display: flex;
    gap: 1rem;
    align-items: center;
    .comparison-card-item-content{
      .title{
        text-transform: capitalize;
        font-size: 1.1rem;
        color: $primaryGray;
        margin-bottom: 0.5rem;
      }
      .value-change{
        display: flex;
        gap: 0.3rem;
        align-items: flex-end;
        .value{  
          font-size: 1.12rem;
          padding-bottom: 0;
        }
        .change{
          font-size: 0.8rem;
          color: $primaryGray;
          padding-bottom: 0;
        }
      }
    }
  }
}