@import '../../../../../Config/Theme/primary_theame.scss';

.dashboard-bu-bg-emissions{
  padding: 1rem;
  .filters{
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: $primaryGreyLight solid 1px;
    margin-bottom: 2rem;
    .filter{
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
  .bu-bg-emission-graphs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    .title{
      font-size: 1rem;
      color: $primaryGray;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}