@import '../../../../Config/Theme/primary_theame.scss';

.dashboard-metric-card{
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: $primaryGreyLight;
  .top-metric-card-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
    .metric-icon{
      height: 1.3rem;
      padding: 1rem;
      background-color: $primaryGreenLight;
      border-radius: 0.5rem;
      }
      .drill-down{
        color: $primaryBlueInfo;
        font-size: 0.8rem;
        cursor: pointer;
        font-weight: 500;
      }
  }
  .metric-title{
    font-size: 1rem;
    color: $primaryGray;
    height: 2rem;
    line-height: 1rem;
    font-weight: bold;
    // width: 12rem;
    // display: inline-block; /* or block, depending on your layout */
    // white-space: nowrap; /* Prevent text from wrapping to the next line */
    // overflow: hidden; /* Hide the overflowed content */
    // text-overflow: ellipsis; /* Add "..." to indicate overflow */
    // cursor: pointer; /* Optional: indicate that it's interactive */

  }
  .metric-value{
    font-size: 2.75rem;
    color: $primaryGreen;
    font-weight: bold;
  }
  .metric-unit{
    font-size: 0.9rem;
    color: $primaryGray;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid $secondaryGray;
    font-weight: bold;
    height: 1.2rem;
  }
  .dashboard-metric-card-progress{
    .dashboard-metric-card-progress-text{
      font-size: 0.8rem;
    }
  }
}