.sample-download{
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
	gap: 0.3rem;
	align-items: center;
	.icon{
	  width: 1rem;
	  height: 1rem;
	}
	p{
		margin: 0;
	}
}

.download-template-bulk-upload{
    // background-color: $primaryGreenLight;
    // padding: 0 1rem;
    display: flex;
    width: 100%;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    .download-template-bulk-upload-btn{
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }