@import '../../../../Config/Theme/primary_theame.scss';

.horizontal-bar-chart-2{
    width: 100%;
    background-color: white;
    .bar-container{
      width: 100%;
      border-radius: 0.5rem;
      padding: 0.5rem 0;
      margin-bottom: 0.5rem;
      gap: 1rem;
      &.selected{
        background-color: $primaryGreyLight
      }
      .bar-name{
        font-size: 0.8rem;
        // only one  line text and show ... if text is more than one line
        color: $primaryGray;
        width: 15rem;
        display: inline-block; /* or block, depending on your layout */
        white-space: nowrap; /* Prevent text from wrapping to the next line */
        overflow: hidden; /* Hide the overflowed content */
        text-overflow: ellipsis; /* Add "..." to indicate overflow */
        cursor: pointer; /* Optional: indicate that it's interactive */
      }
      .bar-wrapper{
        width: 100%;
        display: grid;
        align-items: center ;
        gap: 1rem;
        .bar-wrapper-inner{
          border-radius: 0 0.5rem 0.5rem 0;
          .bar{
            height: 2rem;
            border-radius: 0 0.5rem 0.5rem 0;
          }
        }
        .bar-value{
          color: $primaryGray;
          font-size: 0.8rem;
        }
      }
    }
    .pagination-container{
      display: flex;
      justify-content: center;    
    }
}