@import '../../Config/Theme/primary_theame.scss';

.be-no-data{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .empty-icon{
    padding: 1rem;
    background-color: $primaryGreenLight;
    border-radius: 50%;
    width: fit-content;
    .icon-box{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      padding: 0;
    }
  }
  .description{
    font-size: 1rem;
    color: black;
    margin: 1rem 0;
    width: 80%;
    text-align: center;
  }
}

.be-no-data.small{
  .empty-icon{
    .icon-box{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .description{
    font-size: 0.8rem;
  }
}

.be-no-data.large{
  .empty-icon{
    padding: 1.5rem;
    .icon-box{
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .description{
    font-size: 1.2rem;
  }
}