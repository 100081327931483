@import '../../../../Config/Theme/primary_theame.scss';

.dashboard-drill-down{
  margin-top: 1rem;
  .dashboard-drill-down-header{
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin: 1rem 0;
    .dashboard-drill-down-header-right{
      display: flex;
      gap: 1rem;
    }
  }
  .dashboard-drill-down-content{
    padding: 1rem;
    .chart3-year-selector{
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      .year-selector{
        position: relative;
        .year-selector-lable{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.5rem 1rem;
          width: 8rem;
          border-radius: 0.5rem;
          border:  1px solid $primaryGreyLight;
          &>p{
            font-size: 0.8rem;
            color: $primaryGray;
          }
          .switch-icon {
            height: 1rem;
            transform: rotate(90deg);
          }
        }
        .year-selector-dropdown {
          position: absolute;
          z-index: 10;
          top: 2.5rem;
          right: 0;
          background-color: white;
          border-radius: 0.5rem;
          box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
          border: 1px solid $primaryGreyLight;
          .year-selector-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            width: 8rem;
            &>p{
              font-size: 0.8rem;
              color: $primaryGray;
            }
          }
        }
      }
    }
    .no-target-data{
      margin: 5rem 0;
    }
  }
}