@import '../../../../Config/Theme/primary_theame.scss';

.users-table-container {
    margin:0 1.75rem;
    display: flex;
    flex-direction: column;
    .users-table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-bottom: 1rem;
    }
}