@import '../../../../../../Config//Theme/primary_theame.scss';

.supplier-assessment-add-weightage{
  padding: 1rem;
  .ant-input:focus {
    border-color: transparent !important;
    outline: 0 !important;
    -webkit-box-shadow: no+ne !important;
    box-shadow: none !important;
  }

  .ant-input {
    border-color: transparent !important;
    outline: 0 !important;
    background-color: transparent !important;
    -webkit-box-shadow: no+ne !important;
    box-shadow: none !important;
    padding: 0!important;
    min-width: 0!important;
  }
  .footer-actions{
    margin-top: 2rem;
    display: flex;
    gap: 1rem;
  }
}