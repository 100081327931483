@import '../../../../Config/Theme/primary_theame.scss';

.toggle-button{
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: $primaryGreenLightest;
  width: fit-content;
  .toggle-text{
    font-size: 0.8rem;
    color: $primaryGray;
    font-weight: bold;
    width: fit-content;
  }
  .toggle-text.active{
    color: $primaryGreen;
  }
}