@import '../../../../Config/Theme/primary_theame.scss';

.dashboard-custom{
  .dashboard-custom__header{
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin: 1rem 0;
  }
  .dashboard-custom__graphs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem 2rem;
  }
}