@import '../../../../Config/Theme/primary_theame.scss';

.horizontal-stack-bar{
  width: 100%;
  .title{
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .bar-wrapper{
    display: flex;
    width: 100%;
   >:first-child{
    border-radius: 0.5rem 0 0 0.5rem;
   }
   >:last-child{
    border-radius: 0 0.5rem 0.5rem 0;
   }
   .bar{
     height: 2.1rem;
     position: relative;
    .bar-tooltip{
      position: absolute;
      top: -3rem;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      border-radius: 0.5rem;
      padding: 1rem;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
      border: 1px solid $primaryGreyLight;
      font-size: 0.8rem;
      color: $primaryGray;
      white-space: nowrap;
      display: none;
    }
   }
   .bar:hover{
     .bar-tooltip{
       display: block;
     }
   }
  }
  .label-wrapper{
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    .label-container{
      cursor: pointer;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      .label-color{
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      .label-name{
        font-size: 0.8rem;
      }
    }
  }
}