@import '../../../Config/Theme/primary_theame.scss';

.punch-steps{
	.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
		background-color: $primaryGreyLight;
	  }
	  .ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after ,
	  .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-image: radial-gradient($primaryGreyLight 12px, white 1px);
		background-size: 30px 30px;
		background-position: -19px -19px;
	  }
	  .ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
		width: 147px;
	}
	.ant-steps .ant-steps-item-icon .ant-steps-icon {
		position: relative;
		top: 3.5px;
		color: #1677ff;
		line-height: 1;
		left: -4.5px;
	}
}