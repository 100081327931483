@import '../../../Config/Theme/primary_theame.scss';

.settings {

    .settings-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 0 1.75rem;

        .new-folder {
            border: 1px solid $primaryGreyLight;
            border-radius: 8px;
            display: flex;
            // justify-content: center;
            // align-items: center;
            // height: 90px;
            width: 270px;
            padding: 1rem 1rem;
            padding-bottom: 3rem;
            flex-direction: column;
            gap: 1rem;
            cursor: pointer;

            .circle {
                height: 55px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 55px;
                background-color: #e2f0eb;
                border-radius: 7px;
                .settings-icon{
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }

            
        }
    }

    .active {
        color: $primaryGreen;
    }

    .top-container {
        display: flex;
        align-items: center;
        font-weight: 600;
        gap: 2rem;
        padding: 1rem 1.5rem;
        font-size: 14px;

        .year {
            padding-right: 1rem;
            border-right: 2px solid $primaryGreyLight;
        }

        .ant-form-item {
            margin-bottom: 0 !important;
        }

        .title {
            color: $primaryGray;
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
        }
    }

}
