@import '../../../../Config/Theme/primary_theame.scss';
.progress-bar-container{
	width: 100%;

	.small-txt{
		font-size: 0.8rem;
	}
	.progress-bar-progress-container{
		margin-top: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 0.3rem;
		.progress-bar-arrow{
			height: 1.2rem;
		}
		.progress-bar-arrow.up{
			transform: rotate(180deg);
		}
	}
}